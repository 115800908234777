import React, { useContext, useEffect, useState } from 'react';
import Button from '../../library/Button/button';
import ModuleList from '../../components/BtAdminPanel/ModuleList';
import UserList from '../../components/BtAdminPanel/UserList';
import OrganizationAdmin from '../../utils/OrganizationAdmin/OrganizationAdmin';
import Module from '../../utils/Module/Module';
import UpdateOrganizationAdminModule from '../../utils/Module/UpdateModule';
import { handleApiResponse } from '../../utils/Helper/apiResponseHandler';
import CloudTrailLog from '../../components/AdminPanel/CloudTrailLog';
import { CompanyNameContext } from '../../context/CompanyNameContext';
import PresetModules from '../../components/BtAdminPanel/ModulePresets/PresetModules';
import IModuleList from '../../utils/ModuleList/ModuleList.interface';

/**
 * This common props takes in several parameters.
 * @tabs - An array of objects representing the tabs to be rendered. Each tab object has a label (displayed text) and a key (unique identifier).
 * @activeTabKey - The key of the currently active tab.
 * @onTabClick - A callback function that is called when a tab is clicked. It takes the key of the clicked tab as an argument.
 * @onSubmit - A callback function that is called when the "Create Organization" button is clicked.
 */
interface TabProps {
  tabs: Array<{
    label: string;
    key: string;
  }>;
  activeTabKey: string;
  onTabClick: (key: string) => void;
  onSubmit: () => void;
}

const ChildTab: React.FC<TabProps> = ({ tabs, activeTabKey, onTabClick, onSubmit }) => {
  const [data, setData]: any = useState<Array<IModuleList | null>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [query, setQuery] = useState<string>('');

  const [visibility, setVisibility] = useState<boolean>(false);
  const [dataModule, setDataModule] = useState<any>([]);
  const [isLoadingModule, setIsLoadingModule] = useState<boolean>(true);
  const [queryModule, setQueryModule] = useState<string>('');
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const storedPage = sessionStorage.getItem(`${activeTabKey}_currentPage`);
    return storedPage ? parseInt(storedPage, 10) : 1;
  });
  const [currentPageModule, setCurrentPageModule] = useState<number>(1);
  const [storedLength, setStoredLength] = useState<number>(0);
  const [openPopupIndex, setOpenPopupIndex] = useState<number | null>(null);
  const [responseMessage, setResponseMessage] = useState<string>('Please Wait...');
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [selectedModules, setSelectedModules] = useState<any>([]);
  const { setCompanyNameArray } = useContext(CompanyNameContext);
  const itemsPerPage = 4;
  const itemsPerPageModule = 4;
  const sortedData = [...data].sort((a, b) => a.companyName.localeCompare(b.companyName));
  const filteredData = sortedData.filter((user: { companyName: string }) =>
    user.companyName.toLowerCase().includes(query.toLowerCase())
  );
  const sortedDataModule = [...dataModule].sort((a, b) => a.moduleName.localeCompare(b.moduleName));
  const filteredDataModule = sortedDataModule.filter((user: { moduleName: string }) =>
    user.moduleName.toLowerCase().includes(queryModule.toLowerCase())
  );
  const showPagination = !query;
  const showPaginationModule = true;
  // Calculate the index of the first and last items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItemModule = currentPageModule * itemsPerPageModule;
  const indexOfFirstItemModule = indexOfLastItemModule - itemsPerPageModule;
  const currentData = filteredDataModule.slice(indexOfFirstItemModule, indexOfLastItemModule);
  const currentDataPartnerAdmin = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const needExtraPadding =
    currentDataPartnerAdmin.length < 4 && currentDataPartnerAdmin.length >= 3
      ? 'mt-[10.2rem]'
      : currentDataPartnerAdmin.length < 3 && currentDataPartnerAdmin.length >= 2
      ? 'mt-[15.3rem]'
      : currentDataPartnerAdmin.length < 2 && currentDataPartnerAdmin.length >= 1
      ? 'mt-[20.7rem]'
      : 'mt-[4rem]';

  const needExtraPaddingModule =
    currentData.length < 4 && currentData.length >= 3
      ? 'mt-[11.7rem]'
      : currentData.length < 3 && currentData.length >= 2
      ? 'mt-[15rem]'
      : currentData.length < 2 && currentData.length >= 1
      ? 'mt-[18.7rem]'
      : 'mt-[8rem]';

  const resetPageAndSearch = (newQuery: string) => {
    setQuery(newQuery);
    handlePageChange(1);
  };

  const handlePageChange = (pageNumber: number) => {
    if (storedLength === 0) {
      const previousPage = Math.max(1, currentPage - 1);
      setCurrentPage(previousPage);
    } else {
      setCurrentPage(pageNumber);
    }
  };

  const resetPageAndSearchModule = (newQuery: string) => {
    setQueryModule(newQuery);
    handlePageChangeModule(1);
  };

  const popupCloseAlertHandler = (e: boolean) => {
    setShowAlert(e);
    setResponseMessage('Please Wait...');
  };

  const handlePageChangeModule = (pageNumber: number) => {
    setCurrentPageModule(pageNumber);
  };

  const getAdminOrganizationModule = async () => {
    setIsLoadingModule(true);
    const organizationAdminObj = new Module();
    const result = await organizationAdminObj.getAllModule();
    setDataModule(result);
    setIsLoadingModule(false);
  };

  const handleSubmit = async () => {
    setShowAlert(true);
    const userObj = new UpdateOrganizationAdminModule(selectedModules);
    const updateStatus = await userObj.updateModule(selectedModules);

    const errorMessage = 'Failed to update module';
    const successMessage = 'Modules updated successfully!!';
    const response = handleApiResponse(updateStatus.statusCode, errorMessage, successMessage);
    const responseBody = response.body;
    setSelectedModules([]);
    setResponseMessage(responseBody);
    setSubmitted(true);
    void getAdminOrganizationModule();
  };

  const handleCheckboxChange = async (moduleName: string, isSystemModule: boolean) => {
    // Check if the module is already selected
    const isModuleSelectedIndex = selectedModules.findIndex(
      (module: { moduleName: string }) => module.moduleName === moduleName
    );

    // Update the selected modules array
    if (isModuleSelectedIndex !== -1) {
      // Module is already selected, so remove it
      const updatedModules = [...selectedModules];
      updatedModules.splice(isModuleSelectedIndex, 1);
      await setSelectedModules(updatedModules);
    } else {
      // Module is not selected, so add it
      const updatedModules = [...selectedModules, { moduleName, isSystemModule }];
      await setSelectedModules(updatedModules);
    }

    setSubmitted(false);
  };

  // Close the pop-up form
  const popupCloseHandler = () => {
    setVisibility(false);
    setOpenPopupIndex(null);
  };
  // Open the popup for the specified row
  const popupOpenHandler = (index: number) => {
    setVisibility(true);
    setOpenPopupIndex(index);
  };

  // ******************************** Use Effects ******************************** //
  useEffect(() => {
    if (storedLength === 0) {
      const previousPage = Math.max(1, currentPage - 1);
      setCurrentPage(previousPage);
    }
  }, [storedLength, currentPage]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const getActualLength: any = sessionStorage.getItem('UserList Length');
      if (getActualLength !== null) {
        setStoredLength(parseInt(getActualLength));
      }
    }, 1000); // check every 1 second

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    sessionStorage.setItem(`${activeTabKey}_currentPage`, currentPage.toString());
  }, [currentPage, activeTabKey]);

  useEffect(() => {
    resetPageAndSearch('');
    resetPageAndSearchModule('');
    setCurrentPage(() => {
      const storedPage = sessionStorage.getItem(`${activeTabKey}_currentPage`);
      return storedPage ? parseInt(storedPage, 10) : 1;
    });
  }, [activeTabKey]);

  useEffect(() => {
    const getAdminOrganization = async () => {
      const organizationAdminObj = new OrganizationAdmin();
      const result = await organizationAdminObj.getAllOrganization();
      setData(result);
      setIsLoading(false);
    };
    void getAdminOrganization();
  }, [storedLength >= currentDataPartnerAdmin.length]);

  useEffect(() => {
    const companyNameArray = data.map((item: { companyName: any }) => item.companyName);
    setCompanyNameArray(companyNameArray);
  }, [data]);

  useEffect(() => {
    void getAdminOrganizationModule();
  }, [activeTabKey === tabs[1].key]);

  return (
    <div className="w-full md:w-full">
      <div className="bg-white block md:w-full mx-auto md:ps-0.5 md:pe-0.5 pr-[290px]">
        {/* The tab UI */}
        <div className="lg:col-start-0 col-span-8 pr-4 flex flex-row justify-between md:flex-wrap md:w-[100%] lg:flex-wrap ml-2.5 md:ml-3.5 md:pr-[32px] lg:pr-[32px]">
          <div className="md:flex flex-row lg:w-[23%] md:w-[87%] md:text-sm justify-between md:pl-3.5">
            {/* Map over the tabs array and render each tab item as a list item. */}
            {tabs.map((tab) => (
              <ul key={tab.key}>
                <li className="cursor-pointer" onClick={() => onTabClick(tab.key)}>
                  <span
                    // The active tab is styled differently from inactive tabs.
                    className={
                      activeTabKey === tab.key
                        ? 'inline-block text-black rounded-t-lg lg:w-[10rem] py-4 px-4 text-[18px] text-center active bg-gray'
                        : 'inline-block text-black rounded-t-lg lg:w-[10rem] py-4 px-4 text-[18px] text-center'
                    }
                  >
                    {tab.label}
                  </span>
                </li>
              </ul>
            ))}
          </div>
          {/* The "Create Organization" button */}
          <div className="md:mt-4 lg:mt-4">
            <Button
              className="mb-0 h-[33px] text-[#fff] text-[16px] w-44 cursor-pointer bg-black rounded-[10px]"
              buttonText="Create Organisation"
              onclick={onSubmit} // The onSubmit function is called when the button is clicked.
            />
          </div>
        </div>
      </div>
      {/* The tab content */}
      <div className="flex w-full">
        <div className="md:flex lg:flex flex-row justify-start w-[100%] pl-2.5 pr-5">
          {/* If the active tab is the "Users" tab, render the UserList component, otherwise render the ModuleList component. */}
          {activeTabKey === tabs[0].key ? (
            <UserList
              isLoading={isLoading}
              itemsPerPage={itemsPerPage}
              currentPage={currentPage}
              handlePageChange={handlePageChange}
              filteredData={filteredData}
              showPagination={showPagination}
              currentDataPartnerAdmin={currentDataPartnerAdmin}
              resetPageAndSearch={resetPageAndSearch}
              needExtraPadding={needExtraPadding}
            />
          ) : activeTabKey === tabs[1].key ? (
            <ModuleList
              isLoading={isLoadingModule}
              visibility={visibility}
              popupOpenHandler={popupOpenHandler}
              popupCloseHandler={popupCloseHandler}
              openPopupIndex={openPopupIndex}
              itemsPerPage={itemsPerPageModule}
              currentPage={currentPageModule}
              currentData={currentData}
              handlePageChange={handlePageChangeModule}
              responseMessage={responseMessage}
              showAlert={showAlert}
              popupCloseAlertHandler={popupCloseAlertHandler}
              submitted={submitted}
              selectedModules={selectedModules}
              handleSubmit={handleSubmit}
              handleCheckboxChange={handleCheckboxChange}
              resetPageAndSearch={resetPageAndSearchModule}
              filteredDataModule={filteredDataModule}
              showPagination={showPaginationModule}
              needExtraPadding={needExtraPaddingModule}
            />
          ) : activeTabKey === tabs[2].key ? (
            <CloudTrailLog moduleInfo={dataModule} />
          ) : (
            <PresetModules allModules={dataModule} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ChildTab;
